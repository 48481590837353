@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

a {
  text-decoration: none !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  scroll-behavior: smooth;
  background: #fcfcfc !important;
}

html {
  --Primary: #FD6E47;
  --Secondary: #FFD2C4;
  --Ternary:#FFEEE9;
  --Active : #29a300;
  --InActive :#d70000;
  --Black: #000000;
  --White: #ffffff;
}

/* ------ Form Inputs ID's ------- */
#titleEn,
#titleMr,
#titleHn,
#titleHeader,
#img,
#point,
#duration,
#startTime,
#type,
#calories,
#tagCategory,
#tags,
#descriptionEn,
#descriptionMr,
#descriptionHn,
#name,
#priority,
#category,
#marketPlaceURL,
#productType,
#VideoImage,
#Video,
#selectedCategory,
#author,
#firstNameEn,
#firstNameMr,
#firstNameHn,
#lastNameEn,
#lastNameMr,
#lastNameHn,
#firstName,
#lastName,
#dob,
#guru,
#qualification,
#lessons,
#courseCategory,
#date,
#priorityNo,
#selected
{
  scroll-margin-top: 90px !important;
}

 .LangToggle .switches-container {
  width: 8.5rem;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: var(--Primary);
  line-height: 1.9rem;
  border-radius: 2rem;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.LangToggle .switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

.LangToggle .switches-container input:checked ~ label,
.LangToggle .switches-container #switchMonthly ~ label {
color: var(--White); /* Change color to indicate active state */
}

/* labels for the input (radio) boxes - something to click on */
.LangToggle .switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: var(--Black);
}


.LangToggle .switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 45%;
  padding: 0.12rem;
  z-index: 3;
  transition: transform .5s cubic-bezier(.77, 0, .175, 1);
  /* transition: transform 1s; */
}

/* switch box highlighter */
.LangToggle .switch {
  border-radius: 1rem;
  background: var(--White);
  height: 100%;
}

 
 .LangToggle .switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: var(--Primary) !important;
  font-weight: 600;
  transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
  will-change: opacity;
  position: absolute;
  top: -1px;
  left: 0;
}
.LangToggle .switches-container .disabled{
  background-color: var(--Secondary);
  border-radius: 0 40% 40% 0;
}

/* slide the switch box from right to left */
 .LangToggle .switches-container input:nth-of-type(1):checked~.switch-wrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
 .LangToggle .switches-container input:nth-of-type(2):checked~.switch-wrapper {
  transform: translateX(75%);
}

.LangToggle .switches-container input:nth-of-type(3):checked~.switch-wrapper {
  transform: translateX(150%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
 .LangToggle .switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
 .LangToggle .switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
  opacity: 1;
}
 .LangToggle .switches-container input:nth-of-type(3):checked~.switch-wrapper .switch div:nth-of-type(3) {
  opacity: 1;
}


.form-check-input {
  border: 1px solid #6c6c6c !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

label.form-label {
  color: #575757 !important;
  font-size: 14px !important;
  margin-bottom: 6px !important;
  font-weight: 600;
 }
 
 label.form-label span{
   color: red !important;
   margin: 0px 0px 0px 4px !important;
   font-size: 12px !important;

 }

  #uploadBtn{
    background: transparent;
    border: 2px dashed rgb(199, 199, 199);
    border-radius: 6px;
    padding: 6px 12px;
    font-size: 14px;
    color: #575757;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #imgNote{
      font-size: 11px;
      color: #6f6f6f;
      margin: 4px 0px 0px 2px;
  }

  #uploadBtn:hover{
    cursor: pointer;
  }

  .uploadedFile{
    position: relative !important;
    padding: 8px !important;
  }

   .uploadedFile .removeIcon{
    color: red ;
    position: absolute ;
    top: -7px ;
    right: -7px ;
    transition: 0.2s;
  }

  .uploadedFile .removeIcon:hover{
    cursor: pointer;
    transform: scale(1.19);

  }

  .uploadFile {
    width: max-content;
    height: auto;
    overflow: hidden;
    border: 2px dashed rgb(199, 199, 199);
    margin-top: 6px;
    padding: 6px 12px;
    margin: auto;
  }
  
  .uploadFile img {
    width: 60px;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
  }
  
  .uploadFile h6 {
    margin-bottom: 0px;
  }

  
  .uploadFile p {
    font-size: 14px;
    color: red;
    cursor: pointer;
    margin-left: 25px;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  

.labelButton{
  background: linear-gradient(145deg, #ff7d5a, #FD6E47) !important;
  padding: 5px 14px !important;
  border-radius: 8px !important;
  border: none !important;
  transition: 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1) !important;
  outline: none !important;
  color: white !important;
}



@media (max-width: 640px) {
  .form-layout {
    width: 100%;
  }

  input[type="date"]:before {
    content: "dd-mm-yyyy" !important;
  }

  input[type="date"]:focus:before {
    content: "" !important;
  }

  input[type="date"] {
    display: flex !important;
  }
}

.PageNotFound {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PageNotFound h1 {
  font-weight: 600;
}


/* ----------- Global Buttons & Err Msg Declaration ------------ */
.errMsg,.error {
  color: red !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin: 3px 0px 0px 5px !important;
  text-align: start !important;
}

.InputLabel {
  color: #6f6f6f;
  font-weight: 500;
  margin: 0;
}


.primaryBtn {
  background: linear-gradient(145deg, #ff7d5a, #FD6E47) !important;
  padding: 5px 14px !important;
  border-radius: 8px !important;
  border: none !important;
  transition: 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1) !important;
  outline: none !important;
}

  .primaryBtn:active {
    top: 2px !important;
  }

.primaryBtn:hover {
  color: var(--Primary) !important;
  background: var(--White) !important;
  border: none !important;
  border-radius: 8px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.secondaryBtn {
  color: #000000 !important;
  background: transparent !important;
  border: 1px solid var(--Primary) !important;
  border-radius: 6px !important;
  padding: 5px 11px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
}

.secondaryBtn:hover {
  color: var(--White) !important;
  background: linear-gradient(145deg, #ff7d5a, #FD6E47) !important;
  border: 1px solid transparent !important;
  border-radius: 6px !important;
}
.secondaryBtn:active {
  top: 2px;
}

.backBtn{
  color: #000000 !important;
  background: transparent !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
}

.backBtn:hover{
  transform: translateX(-5px) !important;
}



/* -------------------- Reject Btn --------------------------*/
 .rejectBtn {
        color: red !important;
        background: transparent !important;
        padding: 3px 9px !important;
        border-radius: 8px !important;
        border: 1px solid red !important;
        transition: 0.2s !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        cursor: pointer !important;
        transition: all 0.3s ease !important;
        position: relative !important;
        /* box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
        7px 7px 20px 0px rgba(0,0,0,.1),
        4px 4px 5px 0px rgba(0,0,0,.1) !important; */
        outline: none !important;
        font-size: 14px !important;
        font-weight: 500 !important;
      }

        .rejectBtn:hover{
          color: var(--White) !important;
          background: linear-gradient(145deg, #ff0303, var(--InActive)) !important;
          border: 1px solid transparent !important;
          border-radius:8px !important;
          box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
            7px 7px 20px 0px rgba(0,0,0,.1),
            4px 4px 5px 0px rgba(0,0,0,.1) !important;
        }

        .rejectBtn:active {
          top: 2px !important;
        }

 /* ---------------------------- Approve Btn ---------------------------*/
        
        
  .approveBtn{
      color: var(--White) !important;
      background: linear-gradient(145deg, #2ab300, #29a300) !important;
      padding: 5px 10px;
      border-radius: 8px !important;
      border: none !important;
      transition: 0.2s !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      cursor: pointer !important;
      transition: all 0.3s ease !important;
      position: relative !important;
      box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
      7px 7px 20px 0px rgba(0,0,0,.1),
      4px 4px 5px 0px rgba(0,0,0,.1) !important;
      outline: none !important;
      font-size: 14px !important;
  }

  .approveBtn:active {
    top: 2px !important;
  }

  .approveBtn:hover{
    color: var(--Active) !important;
    background: var(--White) !important;
    border: none !important;
    border-radius:8px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

/* --------------------------- Logout Btn ------------------------- */

  .LogoutBtn{
    color: var(--Black) !important;
    background: transparent !important;
    border: none !important;
    border-radius:6px !important;
    transition: 0.3s !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    transition: 0.3s !important;
  }

  .LogoutBtn:hover{
    color: var(--White) !important;
    background: var(--Primary) !important;
    border: none !important;
    border-radius:6px !important;
    transform: translateX(10px) !important;
  }

.CancelBtn {
  color: #000000 !important;
  border: 1px solid #f7ce40 !important;
  background: transparent !important;
  border-radius: 6px !important;
  transition: 0.3s !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 5px 11px !important;
}

.accordionNav {
  border: 1px solid var(--FoxBlack) !important;
  color: var(--FoxBlack) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  background: transparent !important;
}

.accordionNavActive {
  border: 1px solid transparent !important;
  color: var(--White) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  background: var(--FoxBlack) !important;
}

.pagination {
  width: 100% !important;
}

.pagination .prev,
.pagination .prev:focus,
.pagination .next,
.pagination .next:focus {
  background: transparent !important;
  border: none !important;
  color: var(--Black) !important;
  transition: 0.3s !important;
}

.pagination .prev:hover {
  background: transparent;
  border: none;
  color: var(--Black);
  transform: translateX(-5px);
}

.pagination .next:hover {
  background: transparent;
  border: none;
  color: var(--Black);
  transform: translateX(5px);
}

.pagination .activePageButton {
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: var(--Primary) !important;
  background: var(--White) !important;
  transition: 0.1s !important;
  font-weight: 700;
}

.pagination .pageButton {
  border: 1px solid transparent !important;
  color: var(--Black) !important;
  background: transparent !important;
  transition: 0.1s !important;
}

.pagination select.form-select {
  border: 1px solid #6f6f6f;
  transition: 0.3s;
}

.modal .modal-content {
  border: none !important;
  border-radius: 12px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.modal .modal-content .modal-header {
  font-weight: 600;
}

.modal .modal-content .modal-header h5 {
  margin: 0px;
  font-weight: 500;
  color: var(--Black);
  display: flex;
  align-items: center;
}

.modal .modal-content .modal-body{
  padding: 25px 20px;  
}



.modal .modal-footer{
  background: #ffffff !important;
}

.profile h2 {
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0;
}

.profile .card {
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2) !important;
}

.profile .card div {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.profile .card h6 {
  margin: 0px;
}

.profile .card p {
  margin-left: 6px;
  margin-bottom: 0px;
}
/* --- Login CSS ----*/

.login {
  height: 100vh;
  background: url('./Assets/LoginBg.svg') center center no-repeat;
  background-size: cover;
  text-align: center;
  /* background: linear-gradient(to top, #FFD2C4, #FFEEE9, #FFFCFB); */
  position: relative;
}

.login .logo{
  position: absolute;
  width: 120px !important;
  top: 4vh;
  left: 4vh;
} 

/* .loginNew .left {
  background: url(./Assets/PCMC.jpg) center center no-repeat;
  background-size: cover;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
} */

.login h2 {
  background: linear-gradient(180deg, #4c4c4c, #000000);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.login .card {
  border: 0px;
  border-radius: 15px;
  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  background: rgba( 255, 255, 255, 0.6 );
  /* backdrop-filter: blur( 10px );
  -webkit-backdrop-filter: blur( 200px ); */

  /* From https://css.glass */
background: rgba(255, 255, 255, 0.22);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(104, 104, 104, 0.1);
backdrop-filter: blur(10px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  width: 100%;
  padding: 20px;
}

.login input {
  /* border: none none none none !important; */
  border-color: transparent transparent #363434 transparent !important;
  border-radius: 0px !important;
  background: transparent !important;
}

.login .input-group-text {
  /* border: none none none none !important; */
  border-color: transparent transparent #363434 transparent !important;
  border-radius: 0px !important;
  padding: 4px 10px !important;
  background: transparent;
  margin-left: -2px;
}

.login  input:focus {
  outline: none;
  box-shadow: none;
}

.login .right{
  background: #000000;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px 12px 12px 0px;
}

/* ------ Forgot Pass ------ */
.ForgotPass {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ForgotPass .card {
  border: none;
  border-radius: 12px;
  padding: 0px 20px 25px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ForgotPass .card h3 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 4px;
}

.ForgotPass .card h5 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}

.ForgotPass .card p {
  font-size: 14px;
  color: #575757;
  text-align: center;
  margin-bottom: 8px;
}

/* -------------------- Skeleton Loader CSS ------------------------- */
.loader {
  background-color: #ededed;
  height: 35px;
  border-radius: 2px;
  width: 100%;
}

.tr {
  border-bottom: 1px solid #ddd;
}

.td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

td .loader {
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -40%;
  }
}

.ql-align-center {
  text-align: center !important;
}

.ql-align-left {
  text-align: left !important;
}

.ql-align-right {
  text-align: right !important;
}

.ql-align-justify {
  text-align: justify !important;
}

nav {
  position: fixed;
  left: 36vh;
  right: 0vh;
  background: white;
  box-shadow: 0px 0px 24px rgba(149, 157, 165, 0.2) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  z-index: 1000;
}

nav h5 {
  color: black;
  font-weight: 600;
}
nav h6 {
  color: black;
  font-weight: 600;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
}


@media (max-width: 992px) {
  .lg-nav {
    display: none;
  }

  .mobile-nav {
    position: fixed;
    --gap: 2rem;
    border-radius: 0px;
    inset: 0 0 0 0%;
    z-index: 1000;
    flex-direction: column;
    padding: 20% 5% 20% 5%;
    transform: translateX(100%);
    transition: 0.3s;
    list-style: none;
    margin: 0;
    background: var(--White);
  }

.mobile-nav .menus{
  overflow-y: auto;
  transition: overflow-y 0.5s ease;
  height: 85vh;
  padding: 10px 14px 10px 0px;
}


.mobile-nav .menus::-webkit-scrollbar {
  width: 5px;
}

.mobile-nav .menus::-webkit-scrollbar-track {
  background: #cdcdcd;
  border-radius: 4px;
}

.mobile-nav .menus::-webkit-scrollbar-thumb {
  background: var(--Primary);
  border-radius: 4px;
  border-radius: 6px;
  border: none;
}


  .mobile-nav[data-visible="true"] {
    transform: translateX(0%);
  }

  .mobile-nav-toggle {
    margin: 5px 0px 5px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    /* border: 3px solid #fff; */
  }

  .mobile-nav-toggle .menu-btn__burger {
    width: 35px;
    height: 3px;
    background: var(--Primary);
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    z-index: 9999;
  }
  .mobile-nav-toggle .menu-btn__burger::before,
  .mobile-nav-toggle .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 36px;
    height: 3px;
    background: var(--Primary);
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .mobile-nav-toggle .menu-btn__burger::before {
    transform: translateY(-10px);
  }

  .mobile-nav-toggle .menu-btn__burger::after {
    transform: translateY(10px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
    background: var(--Primary);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
    background: var(--Primary);
  }
}

/* ------ SideBar-lg CSS for Large or Desktop Devices ------ */
@media (min-width: 992px) {
  .lg-nav {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PopupData div {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    margin-bottom: 5px;
  }

  .PopupData div p {
    margin-bottom: 0px;
  }

  .mobile-nav {
    display: none;
  }

  .mobile-nav-toggle {
    display: none;
  }


  .sidenav-lg {
    height: 100vh;
    box-shadow: rgba(165, 171, 176, 0.2) 0px 8px 24px;
    /* background: url('./Assets/LoginBg.svg') !important; */
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0px 8px 0px 16px;
    width: 36vh;
    z-index: 1001;
    /* background: rgba(255, 255, 255, 0.22); */
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
    /* border: 2px solid rgba(255, 255, 255, 0.3); */
}

  .sidenav-lg .menus {
    overflow-y: auto;
    transition: overflow-y 0.5s ease;
    height: 85vh;
    padding: 10px 14px 10px 0px;
  }

  .sidenav-lg .menus::-webkit-scrollbar {
    width: 5px;
  }

  .sidenav-lg .menus::-webkit-scrollbar-track {
    background: #cdcdcd;
    border-radius: 4px;
  }

  .sidenav-lg .menus::-webkit-scrollbar-thumb {
    background: var(--Primary);
    border-radius: 4px;
    border-radius: 6px;
    border: none;
  }

  .sidenav-lg .logoBg {
    background: var(--White);
    border-radius: 0px 0px 12% 12%;
    padding: 10px;
    width: max-content;
    margin: auto;
    text-align: center;
  }

  .sidenav-lg hr {
    border-width: 2px;
    color: var(--White);
    width: 40%;
    margin: auto;
    opacity: 1;
  }

  .sidenav-lg-parent {
    position: relative; 
  }

  .outletDiv {
    position: absolute;
    left: 40vh;
    right: 4vh;
    top: 0vh;
  }
}

@media (max-width: 992px) {
  .outletDiv {
    position: absolute;
    left: 1vh;
    right: 1vh;
    top: 0vh;
  }

  nav {
    left: 0;
    right: 0;
  }
}

/* --- SideBar Accordion CSS for all sizes devices ----*/
.accordion{
  background-color: transparent !important;
}

.accordion .accordion-item{
  border: #000000;
  background: transparent !important;
}

.accordion .accrodion-body {
  background: var(--Black) !important;
  box-shadow: none !important;
  border: none !important;
}

.accordion .activeMenu .accordion-button {
  color: var(--White) !important;
  background: linear-gradient(90deg, #ff7d5a, #FD6E47) !important;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
  7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1) !important;
  /* outline: none !important;
  box-shadow: none !important; */
  border: none !important;
  font-weight: 600 !important;
}

.accordion .activeMenu .accordion-button:hover{
  background: var(--Primary) !important;
  color: var(--White) !important;
}

.accordion .dropDown{
  border-radius: 0px 0px 12px 0px !important;
}

.accordion .activeMenu.dropDown{
 background: var(--Primary) !important;
 border-radius: 10px !important;
}

.accordion .activeMenu.dropDown .accordion-button{
  border-radius: 10px !important;
}

.accordion .accordion-button {
  background: transparent !important;
  color: var(--Black) !important;
  border: none !important;
  font-weight: 400 !important;
  border-radius: 10px !important;
  padding: 12px 20px !important;
  font-weight: 500 !important;
  color: #6b6d71 !important;
  font-size: 15px !important;
}

.accordion .accordion-button:hover{
  background: var(--Secondary) !important;
  color: var(--Black) !important;
}

.accordion .accordion-button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none;
  /* color: #FFFFFF !important; */
  /* background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  color: #ffffff !important; */
}
/*
.accordion .accordion-button:not(.collapsed){
    background: linear-gradient(145deg, #1267cf, #073c7c) !important;
    color: #ffffff !important;
    font-weight: 500 !important;
} */

.accordion-item .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-item .accordion-button:focus:after {
  box-shadow: none;
  outline: #000000 !important;
  border: none !important;
  /* background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important; */
}
.accordion .dropDown .accordion-button:after{ 
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .activeMenu.dropDown .accordion-button:after{ 
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

/* .accordion .dropDown .accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23073c7c'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .dropDown .accordion-button.collapsed:after {
  box-shadow: none;
  outline: #000000 !important;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
} */

.accordion  label:nth-child(1){
  color: rgb(222, 219, 219) !important;
  padding: 1px 0px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin: 0px 0px 0px 0px !important;

}

.accordion  label{
  color: rgb(222, 219, 219) !important;
  padding: 1px 20px !important;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0px 0px 0px;

}

.accordionNav{
  border: 1px solid var(--White) !important;
  color: var(--White) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  background: transparent !important;
}

.accordionNav:hover{
  color: var(--Primary) !important;
  background: var(--White) !important;
}

.accordionNavActive{
  border: 1px solid transparent !important;
  color: var(--White) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important; 
  font-weight: 500 !important;
  background: var(--Primary) !important;
}

/* ------------ Adding Artificial margin to every Oulets component's perent Div  ------------------- */
.outletPadding{
    margin-top: 10vh;
  }


  
/*  ---------------- Fixed header Responsive table CSS ---------------*/

.outer-wrapper {
  margin: 0px ;
  padding: 0px ;
  border-radius: 0px  ;
  background: #ffffff ;
  max-width: 100% ;
  max-height: fit-content ;
}

.outer-wrapper .icon{
  transition: 0.2s;
  width: 28px;
}

.outer-wrapper .icon:hover {
  transform: translateY(-4px) scale(1.2) ;
  cursor: pointer ;
}

.table-wrapper {
  overflow-x: auto;
  width: 100%;
  height: 70vh;
  margin-top: 5px;
  padding: 0px;
}

.table-wrapper::-webkit-scrollbar,
.priorityCards::-webkit-scrollbar {
  width: 6px; 
  height: 6px;
}

.table-wrapper::-webkit-scrollbar-track,
.priorityCards::-webkit-scrollbar-track {
  background: #cdcdcd; 
  border-radius: 6px;
}

.table-wrapper::-webkit-scrollbar-track:hover,
.priorityCards::-webkit-scrollbar-track:hover {
 cursor: pointer;
}

.table-wrapper::-webkit-scrollbar-thumb,
.priorityCards::-webkit-scrollbar-thumb {
  background: var(--Primary);
  border-radius: 4px;
}


/* .table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.outer-wrapper table {
  width: 77vw;
  min-width: max-content !important;
  border-collapse: separate;
  border-spacing: 0px;
}

.outer-wrapper table th {
  z-index: 1 !important;
  position: sticky;
  top: 0px;
  background: hsl(23, 100%, 96%);
  color: var(--Black);
  text-align: left;
  font-weight: 600;
  font-size: 15px;
  outline: 0.7px solid transparent;
  border: 0.5px solid rgba(137, 137, 137, 0.1);
}

.error{
  color: red;
}

.outer-wrapper table th,
.outer-wrapper table td {
  padding: 10px;
}

.outer-wrapper table td {
  text-align: left;
  font-size: 13px;
  border: 0.4px solid rgba(255, 199, 165, 0.1);
  width: max-content;
}

table tfoot {
  position: sticky;
  inset-block-end: 0;
  top: 0px;
}

/* ------------------- Priority Cards ------------------ */
  .priorityCards{
    max-height: 62vh;
    overflow-y: auto;
    overflow-x: hidden;
    transition: overflow-y 0.5s ease;
  }

  .Priority .hint{
    font-size: 13px;
    margin-bottom: 8px;
    color: #a3a3a3;
    font-weight: 400;
  }

  .priorityCards .card{
    border: none;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
  }

  .priorityCards .card img{
    border-radius: 10px 10px 0px 0px;
  }

  .priorityCards .priority{
    position: absolute;
    left: 0;
    top: 0;
    background: var(--Secondary);
    padding: 4px 10px;
    border-radius: 6px 0px 6px 0px;
    font-weight: 600;
  }

  .priorityCards .card input[type='checkbox'] {
    position: absolute;
    width: 0px;
    top: 0px;
    right: 25px;
    transition: color 250ms cubic-bezier(.4,.0,.23,1);
    background: transparent;
    border: transparent;
    border: none;
  }
  
  .priorityCards .card input[type='checkbox']::before {
    content: '';
    display: inline-block;
    width: 25px;
    height: 25px;
    background: transparent;
    border: 2px solid #ffbda9;
    border-radius: 0px 6px 0px 6px;
    cursor: pointer;
    transition: all 250ms cubic-bezier(.4,.0,.23,1);
    background: var(--Secondary);
  }
  
  .priorityCards .card input[type='checkbox']:hover::before {
    background: var(--Secondary);;
  }
  
  .priorityCards .card   input[type='checkbox']:checked::before {
    background: var(--Primary);
    animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1);
    border:var(--Primary);
  }
  
  .priorityCards .card   input[type='checkbox']:checked::after {
    content: '\2713'; /* Unicode character for checkmark */
    position: absolute;
    top: 12px;
    left: 12px;
    transform: translate(-50%, -50%) scale(0);
    color: var(--White);
    animation: checkbox-check 0.3s ease forwards;
  }
  
  @keyframes shrink-bounce {
    0% {
      transform: scale(1);
    }
    33% {
      transform: scale(.85);
    }
    100% {
      transform: scale(1);    
    }
  }
  
  @keyframes checkbox-check {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  
  
 

  
  


/* -------------------- DashCards ----------------- */
.Home h2{
  font-weight: 600;
}

.Home .card {
  position: relative;
  border: none;
  border-radius: 12px;
  overflow: hidden;
  margin: 0px 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.Home .card .card-body{
  padding: 20px;
}

.Home .card:hover{
  cursor: pointer;
}

.Home .card:hover .card-image{
 transform: scale(1.9);
 
}

.Home .card h4{
  font-weight: 700;
  margin-bottom: 2px !important;
}

.Home .card h5{
  font-weight: 600;
  margin-bottom: 0px;
}

.Home .card .card-image {
  transition: 0.2s ease-in-out;
  position: absolute;
  bottom: -10px;
  right: -4px;
  width: 80px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat; 
}

.chartCard {
  text-align: center !important;
  padding: 30px 20px !important;
  border: none !important;
  border-radius: 12px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.refresh {
  transition: 0.3s;
  cursor: pointer;
}

.refresh:hover{
  transform: scale(1.29) !important;

}

.ViewShoppee img{
  width: 60px;
  margin-right: 30px;
}


.Home .card .data,
.ViewContractors .card .data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 16px !important;
}

.Home .card h4,
.ViewContractors .card h4 {
  font-weight: 700;
  margin-bottom: 4px;
}

.Home .card h6,
.SurveyImages .card h6 {
  margin-bottom: 0px;
  font-size: 15px;
}

  .Home .card h6,
  .SurveyImages .card h6{
    margin-bottom: 0px;
    font-size: 15px;
  }

  .Home h3{
    font-weight: 600;
    margin-bottom: 0px;
  }



/* -------- Guru Toggle Navs ---------- */
    .GuruAndQuotes .nav{
      width:max-content ;
      border: none;
      border-radius: 8px;
      box-shadow: rgb(204, 219, 232) 2px 2px 6px 2px inset,
      rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    }

    .GuruAndQuotes .nav .nav-link{
      border-radius: 8px;
      color: var(--Primary);
      padding: 6px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .GuruAndQuotes .nav .nav-link.active{
      color: var(--White);
      background: var(--Primary);
      padding: 6px 12px;
      border: none;
      border-radius: 8px;
      font-weight: 600;
    }


    /* -------- Chrome Tab Navs ---------- */
    

    .ChromeTabs .nav .nav-link{
      color: #3e3e3e;
      padding: 6px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }

    .ChromeTabs .nav-tabs .nav-link.active{
      color: var(--Primary);
      padding: 6px 12px;
      font-weight: 600;
    }

    .filled-tab  {
    color: green !important;
}

 /* ---------------- Priority Scrollable Container ------------------------ */


  .scrollable-tabs-container{
    background: linear-gradient(0deg, #ff663b, #ff7954) !important;
    border-radius: 10px;
    padding: 0px 6px;
    overflow: hidden;
    position: relative;
  }

  .scrollable-tabs-container svg{
    width: 25px;
    height: 25px;
    padding: 4px;
    cursor: pointer;
    color: #ffffff;
    transition: 0.2s;
    font-weight: 900;
  }

  .scrollable-tabs-container svg:hover{
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50% ;
  }

  .scrollable-tabs-container ul{
    display: flex !important;
    gap: 12px;
    padding: 8px 10px;
    margin: 0;
    list-style: none;
    overflow-x: scroll !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
  } 

  .scrollable-tabs-container ul::-webkit-scrollbar{
    display: none;
  }

  .scrollable-tabs-container li{
    color: #ffffff !important;
    text-decoration: none;
    padding: 4px 12px;
    display: inline-block;
    border-radius: 10px;
    white-space: nowrap;
    cursor: pointer;
  }

  .scrollable-tabs-container li.active{
      background: #ffffff;
      color: #000000 !important;
  }

  
  .scrollable-tabs-container .left-arrow,
  .scrollable-tabs-container .right-arrow{
    position: absolute;
    height: 100%;
    width: 100px;
    top: 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
    display: none;
  }

  .scrollable-tabs-container .right-arrow{
    right: 0;
    background: linear-gradient(to left, #ff5121 50%, transparent);
    justify-content: flex-end;
  }

  .scrollable-tabs-container .left-arrow{
    background: linear-gradient(to right, #ff5121 50%, transparent);
  }

  .scrollable-tabs-container .left-arrow.active,
  .scrollable-tabs-container .right-arrow.active{
    display: flex;
  }



/* -------------------- Playstore Privacy Policy ------------------------ */
.PrivacyPolicy h3 {
  font-weight: 700;
  text-align: center;
}

.PrivacyPolicy ul li {
  margin-bottom: 6px;
}


/* for english hindi filed none */
.off{
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* CloudFlare Video Upload  */
.upload-progress {
  text-align: center;
}

.upload-text {
  margin-bottom: 10px;
}

.circular-progress-bar .progress-bar {
  background-color: #ff7d5a; /* Custom color */
}

.custom-progress-bar .progress-bar {
  background-color: #ff7d5a; 
}

/* for image video */
.image-container {
  position: relative;
  width: 60px;
  height: 60px;
}

.image-container img {
  width: 100%;
  height: 100%;
}

.video-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); 
}

.video-icon img {
  opacity:0.5;
  width: 60%;
  height: 60%;
}

.no-video-icon {
  display: none;
}

/* for drag-drop card design */
.priorityCard {
    display: flex;
    align-items: center;
    border: 1px solid gray;
    padding: 10px;
    max-width: 600px;
    margin: 0 auto;
}

.productImage {
    max-width: 150px;
    height: auto;
}

.productTitle {
    font-size: 16;
    margin: 0;
    text-decoration: underline;
    font-weight: 800;
}

.productDescription {
    margin: 10px 0;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

/* ----------------------------------- custome toolbar ----------------------------------- */
.custom-editor-container {
    border-radius: 4px;
    padding: 10px;
    min-height: 150px;
    overflow-y: auto;
}

.toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    border: 1px solid #ccc;
    padding: 8px;
}


.toolbar button,
.toolbar select,
.toolbar input[type="color"] {
  margin-right: 8px;
  background: none;
  border: none;
  cursor: pointer;
}

.toolbar select {
  padding: 2px;
}

.toolbar input[type="file"] {
  display: none;
}

.editor {
  min-height: 150px;
  border: 1px solid #ccc;
  padding: 8px;
}
.card-placeholder {
    border: 1px dashed gray;
    padding: 10px;
    margin: 5px 0;
    background-color: #f9f9f9;
}
.cardplaceholder {
    pointer-events: none; 
    user-select: none; 
}
.editor {
    min-height: 200px;
    border: 1px solid #ccc;
    padding: 10px;
    outline: none;
    resize: vertical; 
}

.editor:focus {
    border-color: black;
}

/* *************************************** card design **************************** */
.card-container {
    display: flex;
    align-items: center;
    border: 1px solid gray;
    border-radius: 8px;
    padding: 15px;
    max-width: 500px;
    height: auto;
    margin-bottom: 15px;
}

.card-image {
    flex: 1;
}

.card-image img {
    width: 50px;
    height: auto;
    border-radius: 4px;
    margin: auto;
}

.card-content {
    flex: 2;
    padding-left: 16px;
}

.card-title {
    font-size: 18px;
    margin: 0px;
    text-decoration: underline;
    font-weight: 800;
    text-align: center;
}

.card-description {
    font-size: 0.875rem;
    margin: 10px 0px;
}

.button-container {
    display: flex;
    justify-content: space-around;
}
.primary-btn {
  background-color: #007bff;
  color: #fff;
}

.secondary-btn {
  background-color: #ddd;
  color: #333;
}
.downloadSample{
  background-color: #fff;
  color:#000;
  padding: 6px 18px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  border: 1px solid #FD6E47;
  border-radius: 10px;
}
.downloadSample:hover{
  background-color: #FD6E47;
  color:#FFF;
  padding: 6px 18px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
