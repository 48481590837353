.custom-datepicker {
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 8px 12px;
    width: 100%; 
    margin-right: 10px; 
}

.custom-datepicker + .mx-3 {
    margin-left: 20px; 
}

.custom-datepicker:last-child {
    margin-right: 0; 
}

.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker-ignore-onclickoutside {
    border: 1px solid #ccc; 
    outline: none; 
    transition: border 0.2s; 
}

.react-datepicker-ignore-onclickoutside:hover,
.react-datepicker-ignore-onclickoutside:focus {
    border: 1px solid #007bff; 
}

.react-datepicker-ignore-onclickoutside:not(:placeholder-shown) {
    border: 1px solid #ccc; 
}

.react-datepicker-ignore-onclickoutside:placeholder-shown {
    border: 1px solid #ccc;
}